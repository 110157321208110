import { environment as parentEnv } from './environment.sales-de-1810';

export const environment = {
  ...parentEnv,
  backendUri: 'https://sales-de-api.hl-display.com/',
  production: true,
  // sentryDsn: 'https://359b75e25571498cb9512017c35d5b7b@sentry.io/1724421',
  quoteConfirmationEmailAddress: 'support@gung.se',
  // orderOurReference: 'Kundtj�nst 08-6837363',
  externalOrderType: 8
};
