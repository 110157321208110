import { environment as defaultEnv } from './default-environment';

export const environment = {
  ...defaultEnv,
  production: false,
  backendUri: 'https://sales-de-api.hl-display.com/', // 'https://hl-display-1810-api-test.gung.io/',
  allowedPriceLists: [],
  hideMobile: false,
  discountCustomerFinancialGroup: {},
  bolag: '1810',
  sales: true,
  analyticsId: '',
  sentryDsn: '',
  quoteConfirmationEmailAddress: 'support@gung.se',
  orderOurReference: '',
  externalOrderType: 8,
  mainCountry: 'de',
  mainCustomer: '',
  stripeApiKey: undefined,
  showFavourites: true,
  getArticlesOutsidePriceLists: true,
  showApprovalQuote: true,
  showCreateSampleOrder: true
};
